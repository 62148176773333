// @file The store for the AuthLoginApp
import type { LoginMethod } from '@@/bits/auth_helper'
import { normalizeThirdPartyLoginMethods } from '@@/bits/auth_helper'
import getCsrfToken from '@@/bits/csrf_token'
import { captureException, captureFetchException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __, p__ } from '@@/bits/intl'
import { clearQueryParamsWithoutReloading, navigateTo } from '@@/bits/location'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '@@/bits/numbers'
import { addQueryParams } from '@@/bits/url'
import { ApiErrorCode, HttpCode, SnackbarNotificationType } from '@@/enums'
import { useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import type { JsonApiData, JsonApiResponse } from '@@/types'
import { HTTPMethod, fetchJson } from '@padlet/fetch'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

interface LoginResponse {
  loginUrl: string
}

export const useAuthOrgLoginStore = defineStore('authOrgLoginStore', () => {
  const globalSnackbarStore = useGlobalSnackbarStore()

  // State
  const username = ref('')
  const password = ref('')
  const tenantName = ref('')
  const tenantLogo = ref('')
  const usernameValidationMessage = ref('')
  const passwordValidationMessage = ref('')
  const isAwaitingServerResponse = ref(false)
  const codeChallenge = ref<string | null>(null)
  const originAppClientId = ref<string | null>(null)
  const pkceState = ref<string | null>(null)
  const originAppRedirectUri = ref<string | null>(null)
  const codeChallengeMethod = ref<string | null>(null)
  const loginMethods = ref<LoginMethod[]>([])
  const minimumPasswordLength = ref(MIN_PASSWORD_LENGTH)
  const maximumPasswordLength = ref(MAX_PASSWORD_LENGTH)
  const inviteLinkCode = ref('')

  // Getters
  const samlLoginMethods = computed(() =>
    loginMethods.value
      .filter((method) => method.name.startsWith('saml'))
      .filter((method) => {
        // Before the SAML setup launch, SAML connections don't have a devMode field, so we show them by default
        // After the launch, they have a devMode field, so we only show them if the dev mode is disabled
        if (method.devMode == null || !method.devMode) return true
        // Only show SAML login methods if the URL has the param `saml_setup=1` and the dev mode is enabled
        return isAppUsing('samlSetup') && method.devMode
      }),
  )
  const thirdPartyAuthLoginMethods = computed<Record<string, LoginMethod>>(() =>
    normalizeThirdPartyLoginMethods(loginMethods.value),
  )
  const isPasswordLoginForTenantEnabled = computed(() => {
    const passwordLoginMethod = loginMethods.value.find((loginMethod) => loginMethod.name === 'password')
    return passwordLoginMethod !== undefined
  })
  const isPkceLogin = computed(
    () =>
      codeChallenge.value != null &&
      codeChallengeMethod.value != null &&
      pkceState.value != null &&
      originAppClientId.value != null &&
      originAppRedirectUri.value != null,
  )

  // Actions
  function initialize(startingState: {
    tenantName?: string
    tenantLogo?: string
    loginMethods?: LoginMethod[]
    originAppClientId?: string | null
    pkceState?: string | null
    codeChallenge?: string | null
    codeChallengeMethod?: string | null
    originAppRedirectUri?: string
    minimumPasswordLength?: number
    maximumPasswordLength?: number
    inviteLinkCode?: string
    errorCode?: string
    erroredSsoService?: string
    email?: string
  }): void {
    tenantName.value = startingState?.tenantName ?? ''
    tenantLogo.value = startingState?.tenantLogo ?? ''
    loginMethods.value = startingState?.loginMethods ?? []
    codeChallenge.value = startingState?.codeChallenge ?? ''
    originAppClientId.value = startingState?.originAppClientId ?? null
    pkceState.value = startingState?.pkceState ?? null
    originAppRedirectUri.value = startingState?.originAppRedirectUri ?? ''
    codeChallengeMethod.value = startingState?.codeChallengeMethod ?? null
    minimumPasswordLength.value = startingState?.minimumPasswordLength ?? MIN_PASSWORD_LENGTH
    maximumPasswordLength.value = startingState?.maximumPasswordLength ?? MAX_PASSWORD_LENGTH
    inviteLinkCode.value = startingState?.inviteLinkCode ?? ''

    if (startingState?.email != null) {
      username.value = startingState.email
    }

    if (startingState?.errorCode != null && startingState?.erroredSsoService != null) {
      clearQueryParamsWithoutReloading()
      openThirdPartyAuthErrorSnackbar({
        errorCode: startingState?.errorCode,
        erroredSsoService: startingState.erroredSsoService,
      })
    } else if (startingState?.errorCode != null) {
      clearQueryParamsWithoutReloading()
      // handle more generic errors not specific to a third party auth service
      openErrorSnackbar({
        errorCode: startingState?.errorCode,
      })
    }

    function openErrorSnackbar({ errorCode }: { errorCode: string }): void {
      if (errorCode === 'LOGIN_TOKEN_EXPIRED' || errorCode === 'SELECT_ACCOUNT_TOKEN_EXPIRED') {
        globalSnackbarStore.setSnackbar({
          message: __('Login token expired'),
          notificationType: SnackbarNotificationType.error,
          persist: true,
        })
      } else if (errorCode === 'NO_USER') {
        globalSnackbarStore.setSnackbar({
          message: p__(
            'Error message when a user is not found for given email or username',
            'Sorry, we could not find a user with the email or username you provided. Please try again.',
          ),
          notificationType: SnackbarNotificationType.error,
        })
      } else {
        // this error is triggered when user exceeds rate limit for verification attempts
        // but we display a generic error and use a non-descriptive error code in the url query param
        globalSnackbarStore.genericFetchError()
      }
    }
  }

  function openThirdPartyAuthErrorSnackbar({
    errorCode,
    erroredSsoService,
  }: {
    errorCode: string
    erroredSsoService: string
  }): void {
    if (errorCode === 'SSO_AUTO_CREATION_DISALLOWED') {
      globalSnackbarStore.setSnackbar({
        message: p__(
          'Error message when user fails to login with Single Sign On (SSO), e.g. Google, Microsoft, and Apple because the tenant does not allow auto creation of accounts',
          'Sorry, we could not find anyone with your %{erroredSsoService} account email. Please contact your administrator to create an account for you.',
          { erroredSsoService },
        ),
        notificationType: SnackbarNotificationType.error,
      })
    } else if (errorCode === ApiErrorCode.INVALID_GOOGLE_APP_LICENSE) {
      globalSnackbarStore.setSnackbar({
        message: p__(
          "Error message when user's tenant is registered with Google App licensing but the user has not been assigned a license",
          'Sorry, your email has not received an app license from your Google administrators to access Padlet. Please contact your Google administrator to create an account for you.',
        ),
        notificationType: SnackbarNotificationType.error,
      })
    } else if (errorCode === ApiErrorCode.NOT_IN_ONEROSTER_SERVER) {
      globalSnackbarStore.setSnackbar({
        message: p__(
          "Error message when user's tenant has OneRoster rostering enabled but the user is not in the OneRoster roster",
          "Sorry, you are not part of your school's roster of users with Padlet access. Please contact your admin to add you to the roster.",
        ),
        timeout: 10000,
        notificationType: SnackbarNotificationType.error,
      })
    } else if (errorCode === 'SSO_INVALID_INFO') {
      globalSnackbarStore.setSnackbar({
        message: p__(
          'Error message when user fails to login with Single Sign On (SSO), e.g. Google, Microsoft, and Apple because the email is invalid',
          'Sorry, your %{erroredSsoService} account email is most likely invalid. Please contact your administrator to create an account for you.',
          { erroredSsoService },
        ),
        notificationType: SnackbarNotificationType.error,
      })
    } else if (errorCode === 'SSO_AUTO_LINKING_DISALLOWED') {
      globalSnackbarStore.setSnackbar({
        message: p__(
          'Error message displayed when the user fails to log in with their Single Sign On (SSO) accounts - e.g. Login with Google, Microsoft, or Apple, because we do not allow auto linking of accounts, due to lack of verification or mismatch in account details',
          'Sorry, we could not log you in using %{erroredSsoService}. Please log in using your email and password instead.',
          { erroredSsoService },
        ),
        notificationType: SnackbarNotificationType.error,
      })
    } else {
      globalSnackbarStore.genericFetchError()
    }
  }

  function setUsername(newUsername: string): void {
    username.value = newUsername
  }

  function setPassword(newPassword: string): void {
    password.value = newPassword
  }

  function setUsernameValidationMessage(newMessage: string): void {
    usernameValidationMessage.value = newMessage
  }

  function setPasswordValidationMessage(newMessage: string): void {
    passwordValidationMessage.value = newMessage
  }

  async function loginWithPassword(): Promise<void> {
    if (username.value === '' || password.value === '') return
    try {
      isAwaitingServerResponse.value = true

      const url = `/api/auth/login`
      const response: JsonApiResponse<LoginResponse> = await fetchJson(url, {
        method: HTTPMethod.post,
        csrfToken: getCsrfToken(),
        jsonData: { username: username.value, password: password.value },
      })
      const loginUrl = (response.data as JsonApiData<LoginResponse>)?.attributes?.loginUrl
      if (isPkceLogin.value) {
        const pkceLoginUrl = addQueryParams(
          loginUrl,
          `code_challenge=${codeChallenge.value as string}&code_challenge_method=${
            codeChallengeMethod.value as string
          }&redirect_uri=${encodeURIComponent(originAppRedirectUri.value as string)}&client_id=${
            originAppClientId.value as string
          }&pkce_state=${pkceState.value as string}`,
        )
        navigateTo(pkceLoginUrl)
      } else {
        navigateTo(loginUrl)
      }
    } catch (e) {
      isAwaitingServerResponse.value = false

      if ([HttpCode.UnprocessableEntity, HttpCode.RateLimitError].includes(e.status)) {
        try {
          const parsedErrorMessage = JSON.parse(e.message)
          const error = parsedErrorMessage.errors[0]

          if (error.code === ApiErrorCode.NO_USER) {
            usernameValidationMessage.value = __('User not found')
          } else if (error.code === ApiErrorCode.WRONG_PASSWORD) {
            passwordValidationMessage.value = __('Wrong password')
          } else if (error.code === ApiErrorCode.TOO_MANY_REQUESTS) {
            passwordValidationMessage.value = __('Too many failed login attempts. Please try again later.')
          } else if (error.code === ApiErrorCode.INVALID_GOOGLE_APP_LICENSE) {
            openThirdPartyAuthErrorSnackbar({ errorCode: error.code, erroredSsoService: 'Google App Licensing' })
          } else if (error.code === ApiErrorCode.NOT_IN_ONEROSTER_SERVER) {
            openThirdPartyAuthErrorSnackbar({ errorCode: error.code, erroredSsoService: 'OneRoster' })
          }
        } catch (jsonException) {
          captureException(jsonException)
        }
      } else {
        captureFetchException(e, { source: 'LandsideLoginWithPassword' })
      }
    }
  }

  return {
    // State
    minimumPasswordLength,
    maximumPasswordLength,
    inviteLinkCode,

    username,
    password,
    usernameValidationMessage,
    passwordValidationMessage,
    isAwaitingServerResponse,
    tenantName,
    tenantLogo,
    // Getters
    samlLoginMethods,
    thirdPartyAuthLoginMethods,
    isPasswordLoginForTenantEnabled,
    isPkceLogin,

    // Actions
    initialize,
    setUsername,
    setPassword,
    setUsernameValidationMessage,
    setPasswordValidationMessage,
    loginWithPassword,
  }
})
