<script setup lang="ts">
import { dir } from '@@/bits/current_dir'
import device from '@@/bits/device'
import { isEmail } from '@@/bits/email'
import { __ } from '@@/bits/intl'
import { goBack, navigateTo, transformCurrentUrl } from '@@/bits/location'
import ImageThumbnail from '@@/library/v4/components/ImageThumbnail.vue'
import OzBackButton from '@@/library/v4/components/OzBackButton.vue'
import OzCheckBox from '@@/library/v4/components/OzCheckBox.vue'
import OzContainedButton, { OzContainedButtonColorScheme } from '@@/library/v4/components/OzContainedButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzInput from '@@/library/v4/components/OzInput.vue'
import OzLabelInput from '@@/library/v4/components/OzLabelInput.vue'
import OzLoadingText from '@@/library/v4/components/OzLoadingText.vue'
import OzPadletLogo from '@@/library/v4/components/OzPadletLogo.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import OzTextLink from '@@/library/v4/components/OzTextLink.vue'
import { useAuthOrgLoginStore } from '@@/pinia/auth_org_login_store'
import { useWindowSizeStore } from '@@/pinia/window_size'
import AuthActionMenu from '@@/vuecomponents/AuthActionMenu.vue'
import AuthBottomNav from '@@/vuecomponents/AuthBottomNav.vue'
import AuthLoginSAMLButton from '@@/vuecomponents/AuthLoginSAMLButton.vue'
import AuthLoginThirdPartyGroupButtons, {
  AuthLoginThirdPartyGroupButtonsType,
} from '@@/vuecomponents/AuthLoginThirdPartyGroupButtons.vue'
import GlobalSnackbar from '@@/vuecomponents/GlobalSnackbar.vue'
import SkipToContentLink from '@@/vuecomponents/SkipToContentLink.vue'
import { computed, ref, watch } from 'vue'

const authOrgLoginStore = useAuthOrgLoginStore()
const windowSizeStore = useWindowSizeStore()

const usernameInput = ref<InstanceType<typeof OzInput>>()
const passwordInput = ref<InstanceType<typeof OzInput>>()

const isPasswordShown = ref(false)
const xActionMenu = ref(false)
const isUsernameInputFocused = ref(false)
const isPasswordInputFocused = ref(false)
const initialScrollY = ref(0)

const isElectronApp = device.electronApp
const isTablet = device.tablet
const isDesktop = device.desktop

const isUsernameValid = computed(() => (authOrgLoginStore.usernameValidationMessage.length ? 'no' : 'yes'))
const isPasswordValid = computed(() => (authOrgLoginStore.passwordValidationMessage.length ? 'no' : 'yes'))
const isLoginButtonDisabled = computed(
  () =>
    !authOrgLoginStore.username.length ||
    !authOrgLoginStore.password.length ||
    !!authOrgLoginStore.usernameValidationMessage.length ||
    !!authOrgLoginStore.passwordValidationMessage.length,
)

const forgotPasswordUrl = computed<string>(() => {
  if (isEmail(authOrgLoginStore.username)) {
    return transformCurrentUrl({}, { path: '/auth/forgot', search: { email: authOrgLoginStore.username } })
  }
  return transformCurrentUrl({}, { path: '/auth/forgot' })
})

watch(
  () => windowSizeStore.visualViewportHeight,
  (newHeight, oldHeight) => {
    if (isDesktop || newHeight >= oldHeight) return

    initialScrollY.value = window.scrollY

    // When people focus on the input, the keyboard will pop up
    // so the visual viewport height will be narrowed down.
    // we want to hide the keyboard when people try to scroll the page
    if (isUsernameInputFocused.value) {
      document.addEventListener('scroll', blurUsernameInputOnScroll)
    } else if (isPasswordInputFocused.value) {
      document.addEventListener('scroll', blurPasswordInputOnScroll)
    }
  },
)

watch(
  () => authOrgLoginStore.isAwaitingServerResponse,
  (value) => {
    const html = document.getElementsByTagName('html')[0]

    // Animate the background color while waiting for the response
    if (value) {
      html.classList.add('dot-grid-loading')
    } else {
      html.classList.remove('dot-grid-loading')
    }
  },
)

function onInputUsernameInput(e): void {
  authOrgLoginStore.setUsername(e.target.value)

  // Reset the username validation message to enable the Continue button when typing on mobile and tablet
  if (authOrgLoginStore.usernameValidationMessage.length) authOrgLoginStore.setUsernameValidationMessage('')
}

function onInputPasswordInput(e): void {
  authOrgLoginStore.setPassword(e.target.value)

  // Reset the password validation message to enable the Login button when typing on mobile and tablet
  if (authOrgLoginStore.passwordValidationMessage.length) authOrgLoginStore.setPasswordValidationMessage('')
}

function onFocusInUsernameInput(): void {
  isUsernameInputFocused.value = true
}

function onFocusInPasswordInput(): void {
  isPasswordInputFocused.value = true
}

function onBlurUsernameInput(): void {
  isUsernameInputFocused.value = false
  document.removeEventListener('scroll', blurUsernameInputOnScroll)
}

function onBlurPasswordInput(): void {
  isPasswordInputFocused.value = false
  document.removeEventListener('scroll', blurPasswordInputOnScroll)
}

function blurUsernameInputOnScroll(): void {
  // Note: the scroll event is fired when people press a key on the soft keyboard only on iPad
  // we just blur the input only if the user scrolls past a certain threshold
  if (isExceededScrollableThreshold(window.scrollY)) {
    usernameInput.value?.blurInput()
  }
}

function blurPasswordInputOnScroll(): void {
  // Note: the scroll event is fired when people press a key on the soft keyboard only on iPad
  // we just blur the input only if the user scrolls past a certain threshold
  if (isExceededScrollableThreshold(window.scrollY)) {
    passwordInput.value?.blurInput()
  }
}

function isExceededScrollableThreshold(currentWindowY: number): boolean {
  const SCROLLABLE_THRESHOLD = 40 // the height of the input field
  return (
    currentWindowY > initialScrollY.value + SCROLLABLE_THRESHOLD ||
    currentWindowY < initialScrollY.value - SCROLLABLE_THRESHOLD
  )
}
</script>

<template>
  <div class="relative flex flex-col items-center">
    <header
      :class="[
        'fixed flex items-center justify-between box-border w-full px-4',
        isDesktop ? 'tablet-portrait:ps-5' : 'tablet-portrait:ps-6',
        !isElectronApp && isDesktop && !windowSizeStore.isSmallerThanTabletPortrait ? 'h-14' : 'h-18',
      ]"
    >
      <SkipToContentLink />

      <OzPadletLogo v-if="!isElectronApp && isDesktop && !windowSizeStore.isSmallerThanTabletPortrait" />

      <OzBackButton
        v-else
        :disabled="authOrgLoginStore.isAwaitingServerResponse"
        :class="[
          { hidden: isElectronApp && !authOrgLoginStore.tenantLogo && !windowSizeStore.isSmallerThanTabletPortrait },
        ]"
        @click="goBack"
      />

      <OzPlainButton
        v-if="isTablet"
        :color-scheme="OzPlainButtonColorScheme.SecondaryIcon"
        :size-preset="OzPlainButtonSizePreset.H28px"
        :aria-label="__('Open more menu dialog')"
        @click="xActionMenu = true"
      >
        <OzIcon :size="20" name="more_vertical" />
      </OzPlainButton>
    </header>

    <main
      id="main-content"
      :class="[
        'fixed',
        'bottom-0',
        'flex',
        'flex-col',
        'box-border',
        'w-full',
        'p-4',
        'pb-6',
        'tablet-portrait:relative tablet-portrait:w-[375px] tablet-portrait:p-0 tablet-portrait:px-4 tablet-portrait:mt-32',
      ]"
      tabindex="-1"
    >
      <div v-if="authOrgLoginStore.tenantLogo" class="w-10 h-10 ml-2 mb-2.5">
        <ImageThumbnail
          class="rounded-full"
          :src="authOrgLoginStore.tenantLogo"
          :title="authOrgLoginStore.tenantName"
          :original-image-width="40"
          :original-image-height="40"
          :width="40"
          :process-image="true"
          :aspect-ratio="1"
          :resize-strategy="'fit'"
        />
      </div>
      <OzBackButton
        v-else
        :disabled="authOrgLoginStore.isAwaitingServerResponse"
        :class="['mb-5 hidden', { 'tablet-portrait:flex': isDesktop }]"
        @click="goBack"
      />

      <div class="mb-5 text-dark-text-100 dark:text-light-text-100">
        <h1 class="px-2 mb-1 text-login-heading-1 font-semibold">
          {{ __('Log in') }}
        </h1>
        <h2 v-if="authOrgLoginStore.tenantName" class="px-2 text-login-body">{{ authOrgLoginStore.tenantName }}</h2>
      </div>

      <AuthLoginThirdPartyGroupButtons
        v-if="Object.keys(authOrgLoginStore.thirdPartyAuthLoginMethods).length"
        :login-methods="authOrgLoginStore.thirdPartyAuthLoginMethods"
        :type="AuthLoginThirdPartyGroupButtonsType.Continue"
        :class="[authOrgLoginStore.samlLoginMethods.length ? 'mb-2' : 'mb-5']"
      />

      <div v-if="authOrgLoginStore.samlLoginMethods.length" class="flex flex-col space-y-2 mb-5">
        <AuthLoginSAMLButton
          v-for="method in authOrgLoginStore.samlLoginMethods"
          :key="method.name"
          :text="`${__('Continue with')} ${method.displayName}`"
          :logo="method.logo"
          @click="navigateTo(method.url)"
        />
      </div>
      <template v-if="authOrgLoginStore.isPasswordLoginForTenantEnabled">
        <p
          v-if="Object.keys(authOrgLoginStore.thirdPartyAuthLoginMethods).length"
          class="text-login-body text-dark-text-100 dark:text-light-text-100 px-2 mb-5"
        >
          {{ __('Or, log in with password') }}
        </p>

        <form novalidate class="flex flex-col mb-6" @submit.prevent="authOrgLoginStore.loginWithPassword">
          <!--
          Use `model-value` and `@input` here instead of `v-model`
          because the input value doesn't get updated on every keypress on android when using `v-model`
          @see https://github.com/vuejs/vue/issues/9777#issuecomment-478831263
        -->

          <OzLabelInput class="mb-2">
            <template #label>
              <label
                for="username-input"
                :class="['text-body-small-posts', 'text-dark-text-100 dark:text-light-text-100', 'px-2']"
              >
                {{ __('Email/username') }}
              </label>
            </template>

            <template #input
              ><OzInput
                id="username-input"
                ref="usernameInput"
                name="username"
                type="email"
                autocomplete="email"
                test-id="usernameInput"
                :disabled="authOrgLoginStore.isAwaitingServerResponse"
                :model-value="authOrgLoginStore.username"
                :validation-message="authOrgLoginStore.usernameValidationMessage"
                placeholder="harrypotter@hogwarts.edu"
                :valid="isUsernameValid"
                class="bg-light-ui-100 dark:bg-dark-ui-100"
                :required="true"
                @input="onInputUsernameInput"
                @focusin="onFocusInUsernameInput"
                @blur="onBlurUsernameInput"
            /></template>
          </OzLabelInput>
          <OzLabelInput class="mb-4">
            <template #label>
              <label
                for="password-input"
                :class="['text-body-small-posts', 'text-dark-text-100 dark:text-light-text-100', 'px-2']"
              >
                {{ __('Password') }}
              </label>
            </template>

            <template #input
              ><OzInput
                id="password-input"
                ref="passwordInput"
                name="password"
                autocomplete="current-password"
                autocapitalize="none"
                test-id="passwordInput"
                :disabled="authOrgLoginStore.isAwaitingServerResponse"
                :type="isPasswordShown ? 'text' : 'password'"
                :model-value="authOrgLoginStore.password"
                :validation-message="authOrgLoginStore.passwordValidationMessage"
                :placeholder="__('Enter password')"
                :valid="isPasswordValid"
                class="bg-light-ui-100 dark:bg-dark-ui-100"
                :required="true"
                @input="onInputPasswordInput"
                @focusin="onFocusInPasswordInput"
                @blur="onBlurPasswordInput"
            /></template>
            <template #focusedAfterInputAccessory>
              <OzCheckBox
                :value="isPasswordShown"
                :label="__('Show')"
                :aria-label="__('Show password as plain text. Warning: this will display your password on the screen.')"
                :dir="dir()"
                class="absolute top-0 end-2 px-2"
                @input="isPasswordShown = !isPasswordShown"
              />
            </template>
          </OzLabelInput>
          <div class="flex justify-center tablet-portrait:justify-end">
            <OzLoadingText
              v-if="authOrgLoginStore.isAwaitingServerResponse"
              :text="__('Logging in...')"
              class="text-heading-4 text-center font-semibold px-5 py-2"
            />
            <OzContainedButton
              v-else
              data-testid="loginButton"
              :color-scheme="OzContainedButtonColorScheme.Primary"
              :disabled="isLoginButtonDisabled"
              class="w-full tablet-portrait:w-auto"
              >{{ __('Log in') }}</OzContainedButton
            >
          </div>
        </form>
        <p
          class="flex items-center justify-center md:justify-start px-2 text-body-small-posts text-dark-text-200 dark:text-light-text-200"
        >
          <OzTextLink :href="forgotPasswordUrl" :text="__('Forgot password?')" class="font-semibold" />
        </p>
      </template>
      <AuthActionMenu v-if="xActionMenu" @cancel="xActionMenu = false" />
      <GlobalSnackbar />
    </main>

    <AuthBottomNav v-if="isDesktop" class="fixed start-6 bottom-6 hidden tablet-portrait:flex" />
  </div>
</template>

<style lang="postcss">
@import '@@/styles/4/kit';
@import '@@/styles/4/dot_grid';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  @extend .dot-grid;
  @apply overflow-hidden;
  @apply bg-left-top;
  @apply h-screen;

  font-size: 16px;
}

@media screen and (orientation: landscape) {
  html {
    @apply overflow-auto;
  }
}
</style>
