// @file Entrypoint for auth login page
import { ww } from '@@/bits/global'
import { setupVueApp } from '@@/bits/vue'
import { useAuthOrgLoginStore } from '@@/pinia/auth_org_login_store'
import AuthOrgLoginApp from '@@/vuecomponents/AuthOrgLoginApp.vue'

void setupVueApp({
  el: '#auth_org_login_app',
  rootComponent: AuthOrgLoginApp,
  usePinia: true,
  initializePiniaStore() {
    void useAuthOrgLoginStore().initialize(ww.vueStartingState)
  },
})
